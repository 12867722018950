import useToken from 'hooks/useToken';
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivateRoute({ element, ...rest }) {
  let { token } = useToken();
  const loggedIn = token != null;

  if (loggedIn) {
    return (
      <Outlet />
    );
  }

  return <Navigate to="/login" replace />;
}
