import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import { fetchMe } from 'apiRequest/me';
import React from 'react';
import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { useQuery } from 'react-query';
import useToken from 'hooks/useToken';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';
import MUINavLink from 'components/MUINavLink';

const menus = [
  {
    'text': 'Servers',
    'to': '/servers',
  },
  {
    'text': 'Staff',
    'to': '/staff',
  },
  {
    'text': 'Transactions',
    'to': '/transactions',
  },
  {
    'type': 'parent',
    'text': 'Reports',
  },
  {
    'type': 'children',
    'menus': [
      {
        'text': 'Transactions',
        'to': '/reports/transactions',
      },
      {
        'text': 'Dealer Payments',
        'to': '/reports/dealer-payments',
      },
    ]
  }
];

export default function Sidebar() {
  const { token } = useToken();
  const { isLoading, isError, data: me, error } = useQuery("me-fetchMe", () => {
    return fetchMe({ token });
  });

  if (isError) {
    toast.error(error.message || defaultError);
    console.error(error);
  }

  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{
        style: {
          top: 64,
          height: 'calc(100% - 64px)',
          width: '256px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        component="nav"
        height="100%"
      >
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {!isLoading && (me?.data.data.name)}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Admin
          </Typography>
        </Box>
        <Divider />

        <Box p={2}>
          <List component="nav">
            {menus.map(({ type, text, to, menus, disabled }) => {
              if (type === 'children') {
                return (
                  <Collapse key={`child_of_${text}`} in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                      {menus.map(({ text, to, disabled }) => {
                        return (
                          <ListItem sx={{ pl: 4 }} key={to} button component={MUINavLink} to={to} disabled={disabled}>
                            <ListItemText>{text}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                );
              }
              if (type === 'parent') {
                return (
                  <ListItem key={text} button disabled={disabled}>
                    <ListItemText>{text}</ListItemText>
                    <ExpandLessIcon />
                  </ListItem>
                );
              }
              return (
                <ListItem key={to} button component={MUINavLink} to={to} disabled={disabled}>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Divider />
        <Box textAlign="center" p={2}>
          <Typography fontSize="small">
            Obet99 Adamin App 0.1
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
