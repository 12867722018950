import React from 'react';
import { Box } from '@mui/material';

import TableDownload from './TableDownload';
import TableFilter from './TableFilter';


export default function ToolbarActions({ download, onDownloadClick, downloadLoading, filter, FilterFields, filterFieldsProps, filterFields, filterSubmit }) {
  return (
    <Box>
      {download && <TableDownload onDownloadClick={onDownloadClick} downloadLoading={downloadLoading} />}
      {filter && <TableFilter FilterFields={FilterFields} filterFieldsProps={filterFieldsProps} filterFields={filterFields} filterSubmit={filterSubmit} />}
    </Box>
  );
}
