import fetch from "utils/fetch";

export const fetchDealerPaymentReports = ({ params, ...rest }) => {
  return fetch({
    url: 'dealer-payments/reports',
    method: "GET",
    params,
    ...rest
  });
};
