import React from 'react';

import PrivateLayout from "layouts/private";
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import useToken from 'hooks/useToken';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';
import { Button, Card, CardActions, CardContent, CardHeader, Container, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { fetchTransaction, updateStatusTransaction } from 'apiRequest/transaction';
import dateTime from 'utils/datetime';
import StatusHighight from 'components/StatusHighight';
import currency from 'utils/currency';
import capitalize from 'lodash.capitalize';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

export default function TransactionShow() {
  const { id } = useParams();

  const { token } = useToken();

  const { data: transaction, isLoading, isError, error, refetch } = useQuery("server-fetchTransaction", () => {
    return fetchTransaction({ token, id, params: { partials: ['server', 'dealer-payment', 'staff'] } });
  });

  const mutation = useMutation(data => {
    return updateStatusTransaction({ token, data, id });
  });


  // eslint-disable-next-line
  const handleStatusUpdateButton = (status) => {
    if (!window.confirm("Are You Sure?")) {
      return;
    }

    const data = {
      status
    };

    mutation.mutate(data, {
      onSuccess: () => {
        toast.success("Update Status Success!");
        refetch();
      },
      onError: (error) => {
        toast.error(error.message || defaultError);
        console.error(error);
      }
    });
  };

  return (
    <PrivateLayout loading={isLoading} error={error} isError={isError}>
      {!isEmpty(transaction?.data.data) && (
        <Container maxWidth="md">
          <Card sx={{ padding: "10px" }}>
            <CardHeader
              action={
                <StatusHighight status={transaction?.data.data.status} />
              }
              title={`${capitalize(transaction?.data.data.type)} - ${transaction?.data.data.id}`}
              subheader={`${dateTime.format(transaction?.data.data.created_at)}`}
            />
            <Divider />
            <CardContent>
              <Stack direction="column" spacing={1.5}>
                <CardText label="Server" value={transaction?.data.data.server?.code} />
                <Divider variant="middle" />
                <CardText label="Game ID" value={transaction?.data.data.game_id_ref} />
                <Divider variant="middle" />
                <CardText label="Amount" value={currency.format(transaction?.data.data.amount)} />
                <Divider variant="middle" />
                <CardText label="Bonus" value={currency.format(transaction?.data.data.bonus)} />
                <Divider variant="middle" />
                <CardText label="Transaction ID" value={transaction?.data.data.transaction_id_ref} />
                <Divider variant="middle" />
                <CardText label="Transferred At" value={dateTime.format(transaction?.data.data.transferred_at)} />
                <Divider variant="middle" />
                <CardText label="Staff" value={
                  transaction?.data.data.staff ? (
                    <>
                      ID: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {transaction?.data.data.staff?.id}< br />
                      Name: {transaction?.data.data.staff?.name} ({transaction?.data.data.staff?.username})
                    </>
                  ) : "-"
                } />
                <Divider variant="middle" />
                <CardText label="Dealer Payment" value={
                  <>
                    {transaction?.data.data.dealer_payment?.provider} < br />
                    Name: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{transaction?.data.data.dealer_payment?.account_name} < br />
                    ID/Phone: {transaction?.data.data.dealer_payment?.account_id}
                  </>
                } />
                <Divider variant="middle" />
                <CardText label="Note" value={transaction?.data.data.note} />
                <Divider variant="middle" />
                <Box>
                  <Typography gutterBottom variant="caption" display="block">
                    Client Receipt
                  </Typography>
                  {
                    transaction?.data.data.client_receipt ? (
                      <a
                        href={transaction?.data.data.client_receipt?.original_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={transaction?.data.data.client_receipt?.original_url}
                          alt="name"
                          style={{
                            width: "fit-content",
                            maxWidth: "100%",
                            maxHeight: "220px"
                          }}
                        />
                      </a>
                    ) : "-"
                  }
                </Box>
                <Divider variant="middle" />
              </Stack>
            </CardContent>
            <CardActions>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  padding: "0 10px"
                }}
              >
                <Button
                  component={RouterLink}
                  to={`/transactions/${transaction?.data.data.id}/update`}
                  color="primary"
                  size="large"
                  variant="outlined"
                >
                  Update
                </Button>

                <LoadingButton
                  onClick={() => handleStatusUpdateButton("completed")}
                  color="success"
                  size="large"
                  variant="contained"
                  loading={false}
                  disabled={transaction?.data.data.status === "completed"}
                >
                  Completed
                </LoadingButton>
                <LoadingButton
                  onClick={() => handleStatusUpdateButton("rejected")}
                  color="error"
                  size="large"
                  variant="contained"
                  loading={false}
                  disabled={transaction?.data.data.status === "rejected"}
                >
                  Rejected
                </LoadingButton>

              </Stack>
            </CardActions>
          </Card>
        </Container>
      )}
    </PrivateLayout >
  );
}

const CardText = ({ label, value }) => {
  return (
    <Box>
      <Typography gutterBottom variant="caption" display="block">
        {label}
      </Typography>
      <Typography variant="body1">
        {value}
      </Typography>
    </Box>
  );
};
