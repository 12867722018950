import { Alert, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import useToken from 'hooks/useToken';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { unexpectedError } from 'utils/message';
import NavBar from './NavBar';
import Sidebar from './Sidebar';

export default function PrivateLayout({ children, loading, isError, error }) {
  const { removeToken } = useToken();
  let navigate = useNavigate();

  if (isError && error?.response.status === 401) {
    removeToken();
    navigate('/login', { replace: true });
  }

  return (
    <Box>
      <NavBar />
      <Box top="60px" position="absolute" width="100%" zIndex="1300">
        {loading && <LinearProgress />}
      </Box>
      <Box display='flex'>
        <Sidebar />
        <Box component="main" ml="256px" p={4} mt="64px" flex="1">
          <Box position="absolute" right="0" minWidth="100px">
            {isError && error?.response.status !== 404 && <Alert severity="error">
              {error?.message || unexpectedError}
            </Alert>}
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
