import { addSeconds } from "date-fns";
import crypto from "utils/crypto";
import Cookies from "js-cookie";

const TOKEN_KEY = crypto.md5(process.env.REACT_APP_TOKEN_KEY || 'auth');

export default function useToken() {
  return {
    setToken: (accessToken, expiresIn) => {
      // expiresIn is minutes, 900 seconds equal to 15 minutes. make cookie expire 15 minutes before actual expiry
      const expires = addSeconds(Date.now(), expiresIn - 900);
      Cookies.set(TOKEN_KEY, crypto.encrypt(accessToken), { expires, secure: true, sameSite: 'strict' });
    },
    removeToken: () => {
      Cookies.remove(TOKEN_KEY);
    },
    token: crypto.decrypt(Cookies.get(TOKEN_KEY))
  };
}
