import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

const createStaffValidation = Yup.object().shape({
  name: Yup.string().required(),
  server_id: Yup.string().required(),
  username: Yup.string().min(6).strict().lowercase().matches(/^[a-z,0-9]+$/, 'The username must only contain letters and numbers.').required(),
  password: Yup.string().min(8).password().required(),
});

export default createStaffValidation;
