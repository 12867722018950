import fetch from "utils/fetch";

export const fetchTransactions = ({ params, ...rest }) => {
  return fetch({
    url: `/transactions`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchTransaction = ({ id, params, ...rest }) => {
  return fetch({
    url: `/transactions/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const updateStatusTransaction = ({ id, data, ...rest }) => {
  return fetch({
    url: `/transactions/${id}/status`,
    method: "PUT",
    data,
    ...rest
  });
};

export const updateTransaction = ({ id, data, ...rest }) => {
  return fetch({
    url: `/transactions/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const isDuplicateTransaction = ({ data, ...rest }) => {
  return fetch({
    url: `/transactions/is-duplicate`,
    method: "POST",
    data,
    ...rest
  });
};

