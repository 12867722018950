import { createTheme, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import WebRoutes from "WebRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false

    },
  }
});
const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <BrowserRouter>
          <WebRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider >
  );
}

export default App;
