import React from "react";
import { NavLink } from "react-router-dom";

const MUINavLink = React.forwardRef((props, ref) => {
  const activeClassName = "Mui-selected";

  return (
    <NavLink
      ref={ref}
      {...props}
      className={({ isActive }) => (
        isActive ? `${props.className} ${activeClassName}` : props.className
      )
      } />
  );
});

export default MUINavLink;
