import { Backdrop, CircularProgress } from "@mui/material";

export default function TableLoading({ loading }) {
  return (
    <Backdrop
      sx={{ position: "absolute" }}
      open={loading}
    >
      <CircularProgress />
    </Backdrop>
  );
}
