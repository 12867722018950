import React from 'react';
import TextInput from './fields/TextInput';
import SubmitButton from './fields/SubmitButton';
import { Divider } from '@mui/material';
import SelectInput from './fields/SelectInput';

export default function StaffForm({ loading, serverOptions }) {
  return (
    <>
      <SelectInput field="server_id" label="Server" options={serverOptions} />
      <TextInput field="name" label="Name" />
      <TextInput field="username" label="Username" />
      <TextInput field="password" label="Password" />
      <Divider />
      <SubmitButton loading={loading}>Submit</SubmitButton>
    </>
  );
}
