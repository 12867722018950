import fetch from "utils/fetch";

export const fetchMe = ({ token, params }) => {
  return fetch({
    url: 'me',
    method: "GET",
    params,
    token
  });
};

export const logoutMe = ({ token }) => {
  return fetch({
    url: 'me/logout',
    method: "POST",
    token
  });
};
