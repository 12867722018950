import React from 'react';

import PrivateLayout from "layouts/private";
import { fetchTransaction, isDuplicateTransaction, updateTransaction } from "apiRequest/transaction";
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import updateTransactionValidation from 'formValidations/updateTransaction';
import FormContainer from 'components/FormContainer';
import useToken from 'hooks/useToken';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';
import TransactionForm from 'components/forms/TransactionForm';
import { fetchDealerPaymentsAll } from 'apiRequest/dealer-payment';
import capitalize from 'lodash.capitalize';
import { fetchPaymentProviders } from 'apiRequest/payment-provider';
import groupBy from 'lodash.groupby';

export default function TransactionUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { token } = useToken();


  const {
    isLoading: isLoadingDealerPayments,
    isError: isErrorDealerPayments,
    data: dealerPayments,
    error: errorDealerPayments
  } = useQuery(
    ["dealerPayment-fetchDealerPaymentsAll"],
    () => {
      return fetchDealerPaymentsAll({ token, params: { server_code: 'obet99', filters: { is_active: 1 } } });
    }, {
    refetchInterval: 300000 // 5 minutes
  });

  if (isErrorDealerPayments) {
    toast.error(errorDealerPayments.message || defaultError);
    toast.error('contact admin');
    console.error(errorDealerPayments);
  }

  const {
    isError: isErrorPaymentProvider,
    data: paymentProviders,
    error: errorPaymentProvider
  } = useQuery("payment-fetchPaymentProviders", () => {
    return fetchPaymentProviders({ token, params: { server_code: 'obet99' } });
  });

  if (isErrorPaymentProvider) {
    toast.error(errorPaymentProvider.message || defaultError);
    toast.error('contact admin');
    console.error(errorPaymentProvider);
  }

  const dealerPaymentGroups = groupBy(dealerPayments?.data.data, (d) => d.provider);
  const dealerPaymentGroupKeys = Object.keys(dealerPaymentGroups);
  const paymentProviderOptions = React.useMemo(() => (
    paymentProviders?.data.data.filter((provider) => dealerPaymentGroupKeys.includes(provider.value))
  ), [paymentProviders, dealerPaymentGroupKeys]);


  const { data: transaction, isLoading, isError: errorTransactionis, error: isErrorTransaction } = useQuery("transaction-fetchTransaction", () => {
    return fetchTransaction({ token, id, params: { partials: ['server', 'dealer_payment'] } });
  });

  const isDuplicateTransactionMutation = useMutation(data => {
    return isDuplicateTransaction({ token, data });
  });

  const mutation = useMutation(data => {
    return updateTransaction({ token, data, id });
  });

  const checkDuplicateTransaction = async (submitData) => {
    return await isDuplicateTransactionMutation.mutateAsync(submitData)
      .then(({ data }) => {
        return data;
      }, (error) => {
        if (error.response?.status !== 422) {
          toast.error(error.message || defaultError);
          console.error(error);
        }
        return true;
      });
  };

  const handleOnSubmit = async ({ values: data }) => {
    const submitData = {
      ...data,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone // for check duplicate transaction
    };

    // skip duplicate checking for pending transaction, will check it on update those fields
    if (data.transaction_id_ref && data.transferred_at) {
      const { is_duplicate, data: duplicateData } = await checkDuplicateTransaction(submitData);
      if (is_duplicate) {
        if (!window.confirm(`The transaction seem duplicate with ${capitalize(duplicateData.type)} ID ${duplicateData.id} please click cancel to double check it.`,)) {
          return;
        }
      }
    }

    mutation.mutate(submitData, {
      onSuccess: () => {
        navigate(`/transactions/${id}`, { replace: true });
      },
      onError: (error) => {
        if (error.response?.status !== 422) {
          toast.error(error.message || defaultError);
          console.error(error);
        }
      }
    });
  };

  return (
    <PrivateLayout
      loading={isLoading || isLoadingDealerPayments}
      error={errorTransactionis}
      isError={isErrorTransaction}>
      {!isEmpty(transaction?.data.data) &&
        <FormContainer
          initialValues={transaction?.data.data}
          title="Edit Transaction"
          subheader={transaction?.data.data.name}
          onSubmit={handleOnSubmit}
          validationSchema={updateTransactionValidation}
          serverError={isDuplicateTransactionMutation.error || mutation.error}
        >
          <TransactionForm
            update={true}
            loading={mutation.isLoading}
            paymentProviderOptions={paymentProviderOptions}
            dealerPaymentGroups={dealerPaymentGroups}
          />
        </FormContainer>
      }
    </PrivateLayout >
  );
}
