import { Typography } from '@mui/material';
import React from 'react';

export default function StatusHighight({ status }) {
  let color = "text";

  switch (status) {
    case "pending":
      color = "warning.main";
      break;
    case "completed":
      color = "success.main";
      break;
    case "rejected":
      color = "error.main";
      break;
    default:
      color = "text";
  }

  return (<Typography variant="span" color={color}>{status}</Typography>);
}
