import React from 'react';

import PrivateLayout from "layouts/private";
import { fetchStaff, updateStaff } from "apiRequest/staff";
import { useNavigate, useParams } from 'react-router-dom';
import StaffForm from 'components/forms/StaffForm';
import isEmpty from 'lodash.isempty';
import updateStaffValidation from 'formValidations/updateStaff';
import FormContainer from 'components/FormContainer';
import useToken from 'hooks/useToken';
import { useMutation, useQuery } from 'react-query';
import { fetchServers } from 'apiRequest/server';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';

export default function StaffUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { token } = useToken();

  const { data: servers } = useQuery("server-fetchServers", () => {
    return fetchServers({ token });
  });

  const serverOptions = servers?.data.data.map((server) => {
    return {
      value: server.id,
      label: server.code
    };
  });

  const { data: staff, isLoading, isError, error } = useQuery("server-fetchStaff", () => {
    return fetchStaff({ token, id });
  });

  const mutation = useMutation(data => {
    return updateStaff({ token, data, id });
  });


  const handleOnSubmit = async ({ values: data }) => {
    const submitData = {
      ...data
    };

    mutation.mutate(submitData, {
      onSuccess: () => {
        navigate('/staff', { replace: true });
      },
      onError: (error) => {
        if (error.response?.status !== 422) {
          toast.error(error.message || defaultError);
          console.error(error);
        }
      }
    });
  };

  return (
    <PrivateLayout loading={isLoading} error={error} isError={isError}>
      {!isEmpty(staff?.data.data) &&
        <FormContainer
          initialValues={staff?.data.data}
          title="Edit Staff"
          subheader={staff?.data.data.name}
          onSubmit={handleOnSubmit}
          validationSchema={updateStaffValidation}
          serverError={mutation.error}
        >
          <StaffForm loading={mutation.isLoading} serverOptions={serverOptions} />
        </FormContainer>
      }
    </PrivateLayout >
  );
}
