import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useField } from 'informed';

export default function DateInput(props) {
  const { fieldState, fieldApi, render, ref, userProps } = useField(props);

  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, label, size, textFieldProps, ...rest } = userProps;

  return render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="yyyy/MM/dd"
        renderInput={(props) => <TextField
          {...props}
          margin="dense"
          error={Boolean(fieldState.error)}
          helperText={fieldState.error || " "}
          size={size}
          {...textFieldProps}
        />}
        label={label}
        {...rest}
        ref={ref}
        value={!value && value !== 0 ? '' : value}
        onChange={newValue => {
          setValue(newValue);
          if (onChange) {
            onChange(newValue);
          }
        }}
        onBlur={e => {
          setTouched(true);
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
    </LocalizationProvider>
  );
}
