import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

const updateTransactionValidation = Yup.object().shape({
  type: Yup.string().required().oneOf(['deposit', 'withdraw']),
  dealer_payment_id: Yup.number().required(),
  game_id_ref: Yup.string().required(),
  amount: Yup.number().required(),
  bonus: Yup.number(),
  note: Yup.string(),
  transaction_id_ref: Yup.string().required(),
  transferred_at: Yup.date().required()
});

export default updateTransactionValidation;
