import React from 'react';

import PrivateLayout from "layouts/private";
import { createStaff } from "apiRequest/staff";
import StaffForm from 'components/forms/StaffForm';
import createStaffValidation from 'formValidations/createStaff';
import FormContainer from 'components/FormContainer';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { defaultError } from 'utils/message';
import toast from 'react-hot-toast';
import useToken from 'hooks/useToken';
import { fetchServers } from 'apiRequest/server';

export default function StaffCreate() {
  const navigate = useNavigate();

  const { token } = useToken();
  
  const { data: servers } = useQuery("server-fetchServers", () => {
    return fetchServers({ token });
  });

  const serverOptions = servers?.data.data.map((server) => {
    return {
      value: server.id,
      label: server.code
    };
  });


  const mutation = useMutation(data => {
    return createStaff({ token, data });
  });

  const handleOnSubmit = async ({ values: data }) => {
    const submitData = {
      ...data
    };

    mutation.mutate(submitData, {
      onSuccess: () => {
        navigate('/staff', { replace: true });
      },
      onError: (error) => {
        if (error.response?.status !== 422) {
          toast.error(error.message || defaultError);
          console.error(error);
        }
      }
    });
  };

  return (
    <PrivateLayout>
      <FormContainer
        title="Staff"
        subheader="Add New Staff"
        onSubmit={handleOnSubmit}
        validationSchema={createStaffValidation}
        serverError={mutation.error}
      >
        <StaffForm loading={mutation.isLoading} serverOptions={serverOptions} />
      </FormContainer>
    </PrivateLayout>
  );
};
