import { fetchServers } from 'apiRequest/server';
import ISTable from 'components/lSTable';
import useToken from 'hooks/useToken';
import PrivateLayout from 'layouts/private';
import React from 'react';
import { useQuery } from 'react-query';
import dateTime from 'utils/datetime';


export default function ServerList() {
  const { token } = useToken();

  const { isLoading, isError, data: servers, error } = useQuery("server-fetchServers", () => {
    return fetchServers({ token });
  });

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'code',
      label: 'Server',
      selector: row => row.code,
    },
    {
      key: 'created_at',
      label: 'Date',
      selector: row => dateTime.format(row.created_at),
    }
  ], []);

  return (
    <PrivateLayout loading={isLoading} isError={isError} error={error}>
      <ISTable
        maxWidth="md"
        size="medium"
        title="Servers"
        loading={isLoading}
        columns={columns}
        data={servers?.data.data}
      />
    </PrivateLayout>
  );
}
