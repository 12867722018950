import { useMemo, useState } from 'react';
import { fetchDealerPaymentReports } from 'apiRequest/dealer-payment-report';
import PrivateLayout from "layouts/private";
import ISTable from 'components/lSTable';
import { Button, Grid, Stack } from '@mui/material';
import TableButtonsContainer from 'components/TableButtonsContainer';
import { useQuery } from 'react-query';
import useToken from 'hooks/useToken';
import { endOfDay, endOfMonth, endOfYear, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { Form } from 'informed';
import DateInput from 'components/forms/fields/DateInput';
import { Box } from '@mui/system';
import { fetchServers } from 'apiRequest/server';
import SelectInput from 'components/forms/fields/SelectInput';

export default function DealerPaymentReportList() {
  const { token } = useToken();
  const [params, setParams] = useState({
    sort_by: {
      "key": "id",
      "order": "asc"
    }
  });

  const { isLoading, isError, data: list, error } = useQuery(
    ['dealerPayment-fetchDealerPaymentReports'.name, params],
    () => {
      return fetchDealerPaymentReports({ token, params });
    });

  const { data: servers } = useQuery("server-fetchServers", () => {
    return fetchServers({ token });
  });

  const serverOptions = servers?.data.data.map((server) => {
    return {
      value: server.id,
      label: server.code
    };
  });

  const hanldeSortClick = (data) => {
    setParams({ ...params, sort_by: data });
  };

  const hanldeFilterSubmit = (data) => {
    setParams({
      ...params,
      filters: { ...params.filters, ...data }
    });
  };

  const columns = useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true
    },
    {
      key: 'provider',
      label: 'Provider',
      selector: row => row.provider,
    },
    {
      key: 'account_id',
      label: 'Account ID',
      selector: row => row.account_id,
    },
    {
      key: 'account_name',
      label: 'Account Name',
      selector: row => row.account_name,
    },
    {
      key: 'deposit_amount',
      label: 'Deposit Amount',
      selector: row => row.deposit_amount,
      currency: true
    },
    {
      key: 'withdraw_amount',
      label: 'Withdraw Amount',
      selector: row => row.withdraw_amount,
      currency: true
    },
    {
      key: 'yield_amount',
      label: 'Yield Amount',
      selector: row => row.deposit_amount - row.withdraw_amount,
      currency: true,
      highlight: true
    },
    {
      key: 'bonus_amount',
      label: 'Bonus Amount',
      selector: row => row.bonus_amount,
      currency: true,
    },
  ], []);

  return (
    <PrivateLayout loading={isLoading} isError={isError} error={error}>
      <TableButtonsContainer>
        <RangeButtons onPeriodChange={hanldeFilterSubmit} />
      </TableButtonsContainer>
      <ISTable
        rowKey="id"
        title={`Dealer Payments Report`}
        loading={isLoading}
        columns={columns}
        data={list?.data.data}
        count={list?.data.total}
        sortClick={hanldeSortClick}
        defaultSort={{ key: 'id', 'order': 'asc' }}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFieldsProps={{ serverOptions }}
        filterFields={['server_id']}
      />
    </PrivateLayout>
  );
}

const RangeButtons = ({ onPeriodChange }) => {
  const [activePeriod, setActivePeriod] = useState("All");
  const [valuesChanged, setValuesChanged] = useState(false);
  const date = new Date();
  const spefictyRanges = [
    {
      label: "All",
      transferredAt: {
        from: undefined,
        to: undefined,
      }
    },
    {
      label: "Today",
      transferredAt: {
        from: startOfDay(date).toISOString(),
        to: endOfDay(date).toISOString(),
      }
    },
    {
      label: "This Month",
      transferredAt: {
        from: startOfMonth(date).toISOString(),
        to: endOfMonth(date).toISOString(),
      }
    },
    {
      label: "This Year",
      transferredAt: {
        from: startOfYear(date).toISOString(),
        to: endOfYear(date).toISOString(),
      }
    }
  ].map(({ label, transferredAt }) => {
    return (
      <Button
        onClick={() => {
          setActivePeriod(label);
          onPeriodChange({
            transferred_at_from: transferredAt.from,
            transferred_at_to: transferredAt.to
          });
        }}
        disabled={activePeriod === label}
        key={label}
        variant="contained"
      >
        {label.toLocaleUpperCase()}
      </Button>
    );
  });

  return (
    <>
      <Box>
        <Stack direction="row" spacing={1.5} >
          {spefictyRanges}
        </Stack>
      </Box>
      <Form
        onValueChange={({ dirty }) => {
          if (dirty) {
            setValuesChanged(true);
          }
        }}
        onSubmit={({ values }) => {
          setActivePeriod("Custom");
          setValuesChanged(false);
          onPeriodChange({
            transferred_at_from: values.transferred_at_from?.toISOString(),
            transferred_at_to: values.transferred_at_to?.toISOString()
          });
        }}>
        <Stack direction="row" spacing={1.5} >
          <DateInput field="transferred_at_from" size="small" label="From" textFieldProps={{ margin: "none", helperText: "" }} />
          <DateInput field="transferred_at_to" size="small" label="To" textFieldProps={{ margin: "none", helperText: "" }} />
          <Button
            type="submit"
            variant='contained'
            disabled={activePeriod === "Custom" && !valuesChanged}>
            Enter
          </Button>
        </Stack>
      </Form>
    </>
  );
};

const FilterFields = ({ serverOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="250px">
      <Grid item xs={6}>
        <SelectInput field="server_id" label="Server" size="small" margin="none" helperText={false}
          options={serverOptions}
        />
      </Grid>
    </Grid>
  );
};
