import React from 'react';

import Login from "pages/Login";
import NotFound from "pages/NotFound";
import PrivateRoute from "PrivateRoute";

import { Navigate, Route, Routes } from "react-router-dom";
import StaffList from 'pages/staff/list';
import StaffCreate from 'pages/staff/create';
import StaffUpdate from 'pages/staff/update';
import ServerList from 'pages/servers/list';
import TransactionList from 'pages/transactions/list';
import TransactionShow from 'pages/transactions/show';
import TransactionUpdate from 'pages/transactions/update';
import TransactionReportDaily from 'pages/transaction-reports/daily';
import TransactionReportMontly from 'pages/transaction-reports/monthly';
import TransactionReportYearly from 'pages/transaction-reports/yearly';
import DealerPaymentReportList from 'pages/dealer-payments-reports/list';

export default function WebRoutes() {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route index element={<ServerList />} />

        <Route path="/servers" element={<ServerList />} />

        <Route path="/transactions" element={<TransactionList />} />
        <Route path="/transactions/:id" element={<TransactionShow />} />
        <Route path="/transactions/:id/update" element={<TransactionUpdate />} />

        <Route path="/reports/transactions" element={<Navigate to="/reports/transactions/daily" replace={true} />} />
        <Route path="/reports/transactions/daily" element={<TransactionReportDaily />} />
        <Route path="/reports/transactions/monthly" element={<TransactionReportMontly />} />
        <Route path="/reports/transactions/yearly" element={<TransactionReportYearly />} />

        <Route path="/reports/dealer-payments" element={<DealerPaymentReportList />} />

        <Route path="/staff" element={<StaffList />} />
        <Route path="/staff/create" element={<StaffCreate />} />
        <Route path="/staff/:id/update" element={<StaffUpdate />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
