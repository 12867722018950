import { Box, Card, CardContent, Container, Paper, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { LoginOutlined as LoginOutlinedIcon } from '@mui/icons-material';
import logo from 'logo.png';
import { login } from "apiRequest/auth";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import useToken from "hooks/useToken";
import { fetchMe } from "apiRequest/me";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { defaultError, wrongCredential } from "utils/message";
import { Form } from "informed";
import TextInput from "components/forms/fields/TextInput";

export default function Login() {
  let navigate = useNavigate();
  const { setToken, token } = useToken();

  const mutation = useMutation(data => {
    return login({ data });
  });

  const fetchMeMutate = useMutation(() => {
    return fetchMe({ token });
  });

  useEffect(() => {
    if (token) {
      async function process() {
        fetchMeMutate.mutate(null, {
          onSuccess: () => {
            navigate('/', { replace: true });
          }
        });
      }

      process();
    }
  }, [token, fetchMeMutate, navigate]);

  const handleSubmit = ({ values: data }) => {
    mutation.mutate(data, {
      onSuccess: ({ data }) => {
        setToken(data.access_token, data.expires_in);
        navigate('/', { replace: true });
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          toast.error(wrongCredential);
        } else {
          console.error(error);
          toast.error(defaultError);
        }
      }
    });
  };

  return (
    <Box>
      <Box sx={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}>
        <Container maxWidth="sm">
          <Box sx={{ textAlign: "center" }}>
            <img src={logo} height="100" alt="Lotto ASEAN" />
          </Box>
          <Card>
            <Paper sx={{ p: 2, textAlign: "center" }}>
              <Typography variant="h4">Login</Typography>
            </Paper>
            <CardContent>
              <Form onSubmit={handleSubmit} className="box" autoComplete="off">
                <TextInput field="username" label="Username" required />
                <TextInput field="password" label="Password" type="password" required />
                <LoadingButton
                  fullWidth
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={mutation.isLoading}
                  loadingPosition="start"
                  startIcon={<LoginOutlinedIcon />}
                >
                  Login
                </LoadingButton>
              </Form>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}
