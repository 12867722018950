import React, { useMemo } from 'react';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import { IconButton, Popover, Paper, Box, Typography, Button, Grid } from '@mui/material';
import { Form } from "informed";
import merge from 'lodash.merge';

export default function TableFilter({ FilterFields, filterFieldsProps, filterFields, filterSubmit }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeFilter, setActiveFilter] = React.useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterable = useMemo(() => {
    return filterFields.reduce((obj, item) => {
      return { ...obj, [item]: undefined };
    }, {});
  }, [filterFields]);

  const handleFilterSubmit = ({ values }) => {
    setActiveFilter(merge(filterable, values))
    filterSubmit(merge(filterable, values));
  };

  const handleFilterReset = () => {
    setActiveFilter(filterable)
    filterSubmit(filterable);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Box sx={{ p: 2 }}>
            <Typography variant="body1">
              Filters
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Form onSubmit={handleFilterSubmit} onReset={handleFilterReset} initialValues={activeFilter}>
                <FilterFields {...filterFieldsProps} />
                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Button type="submit" variant="contained" fullWidth>Filter</Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button type="reset" variant="contained" fullWidth color="warning">Reset</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
}
