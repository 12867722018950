import { Box, Button, Card, Grid, InputAdornment, SvgIcon } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import TextInput from 'components/forms/fields/TextInput';
import { Form } from "informed";

import React from 'react';

export default function SearchBox({ searchSubmit, searchFields = [] }) {
  const handleOnSubmit = ({ values }) => {
    searchSubmit(values.search);
  };

  const handleOnReset = () => {
    searchSubmit("");
  };

  return (
    <Form onSubmit={handleOnSubmit} onReset={handleOnReset}>
      <Card sx={{ mb: 2 }}>
        <Box sx={{ maxWidth: 500, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextInput
                helperText={false}
                margin="none"
                field="search"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder={`Search ${searchFields.join(", ")} `}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <Button type="submit" fullWidth variant="contained">Search</Button>
            </Grid>
            <Grid item xs={2}>
              <Button type="reset" fullWidth variant="contained" color="warning">Reset</Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Form>
  );
}
