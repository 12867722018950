import fetch from "utils/fetch";

export const fetchStaffs = ({ params, ...rest }) => {
  return fetch({
    url: 'staff',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchStaff = ({ id, params, ...rest }) => {
  return fetch({
    url: `staff/${id}`,
    method: "GET",
    params,
    ...rest
  });
};


export const updateStaff = ({ id, data, ...rest }) => {
  return fetch({
    url: `staff/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const createStaff = ({ data, ...rest }) => {
  return fetch({
    url: `staff`,
    method: "POST",
    data,
    ...rest
  });
};
