import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from 'informed';
import React from 'react';

export default function SelectInput(props) {
  const { fieldState, fieldApi, render, ref, userProps } = useField(props);

  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, label, size, helperText, margin = "dense", options, ...rest } = userProps;

  return render(
    <React.Fragment>
      <FormControl fullWidth margin={margin} error={Boolean(fieldState.error)} size={size}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          {...rest}
          ref={ref}
          value={!value && value !== 0 ? '' : value}
          onChange={e => {
            setValue(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={e => {
            setTouched(true);
            if (onBlur) {
              onBlur(e);
            }
          }}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {options?.map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
        </Select>
        <FormHelperText>{helperText === false ? "" : fieldState.error || " "}</FormHelperText>
      </FormControl>
    </React.Fragment >
  );
}
