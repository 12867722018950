import fetch from "utils/fetch";


export const fetchPaymentProviders = ({ params, ...rest }) => {
  return fetch({
    url: `/payment-providers`,
    method: "GET",
    params,
    ...rest
  });
};
