import fetch from "utils/fetch";

export const login = ({ data }) => {
  return fetch({
    baseURL: process.env.REACT_APP_OAUTH_BASE_URL,
    url: '/token',
    method: "POST",
    data: {
      ...data,
      grant_type: 'password',
      client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET
    }
  });
};
