import { CssBaseline, Box, Container, Typography, Link, ButtonBase } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography align="center"
          color="textPrimary"
          variant="h3"
        >
          404 The Request URL Not Found
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="subtitle1"
        >
          Go to <Link to="/" component={RouterLink}>Home Page</Link>
          &nbsp;or&nbsp;
          <Link to="/" onClick={() => navigate(-1)} component={ButtonBase}>
            Previous Page
          </Link>
        </Typography>

      </Container>
    </Box>
  );
}
