import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import PrivateLayout from "layouts/private";
import ISTable from 'components/lSTable';
import { Button, Grid } from '@mui/material';
import TableButtonsContainer from 'components/TableButtonsContainer';
import capitalize from 'lodash.capitalize';
import { useQuery } from 'react-query';
import useToken from 'hooks/useToken';
import { fetchServers } from 'apiRequest/server';
import SelectInput from './forms/fields/SelectInput';

export default function TransactionReportPageContainer({ fetch }) {

  const { token } = useToken();
  const [params, setParams] = useState({
    sort_by: {
      "key": "date",
      "order": "desc"
    }
  });

  const { isLoading, isError, data: list, error } = useQuery(
    [fetch.name, params],
    () => {
      return fetch({ token, params });
    });

  const { data: servers } = useQuery("server-fetchServers", () => {
    return fetchServers({ token });
  });

  const serverOptions = servers?.data.data.map((server) => {
    return {
      value: server.id,
      label: server.code
    };
  });

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const hanldeSortClick = (data) => {
    setParams({ ...params, sort_by: data });
  };

  const hanldeFilterSubmit = (data) => {
    setParams({
      ...params,
      filters: { ...params.filters, ...data }
    });
  };

  const columns = React.useMemo(() => [
    {
      key: 'date',
      label: 'Date',
      selector: row => row.date,
      sort: true
    },
    {
      key: 'completed_deposit_amount',
      label: 'Deposit Amount',
      selector: row => row.completed_deposit_amount,
      currency: true
    },
    {
      key: 'completed_withdraw_amount',
      label: 'Withdraw Amount',
      selector: row => row.completed_withdraw_amount,
      currency: true
    },
    {
      key: 'completed_yield_amount',
      label: 'Yield Amount',
      selector: row => row.completed_deposit_amount - row.completed_withdraw_amount,
      currency: true,
      highlight: true
    },
    {
      key: 'completed_bonus_amount',
      label: 'Bonus Amount',
      selector: row => row.completed_bonus_amount,
      currency: true,
    },
    {
      key: 'completed_withdraw_count',
      label: 'Withdraw Count',
      selector: row => row.completed_withdraw_count,
    },
    {
      key: 'completed_deposit_count',
      label: 'Deposit Count',
      selector: row => row.completed_deposit_count,
    },
  ], []);

  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const current = `${pathArray.pop()}`;
  const pageName = `${pathArray.pop()}`;

  return (
    <PrivateLayout loading={isLoading} isError={isError} error={error}>
      <TableButtonsContainer>
        <RangeButtons pageName={pageName} current={current} />
      </TableButtonsContainer>
      <ISTable
        rowKey="date"
        title={`${capitalize(current)} ${capitalize(pageName)} Report`}
        loading={isLoading}
        columns={columns}
        data={list?.data.data}
        pagination
        count={list?.data.total}
        page={list?.data.current_page}
        rowsPerPage={list?.data.per_page}
        onPageChange={handlePageChange}
        sortClick={hanldeSortClick}
        defaultSort={{ key: 'date', 'order': 'desc' }}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFieldsProps={{ serverOptions }}
        filterFields={['server_id']}
      />
    </PrivateLayout>
  );
}

const RangeButtons = ({ pageName, current }) => {
  return ['daily', 'monthly', 'yearly'].map((range) => {
    return (
      <Button
        disabled={current === range}
        key={range}
        variant="contained"
        component={RouterLink}
        to={`/reports/${pageName}/${range}`}>
        {range.toLocaleUpperCase()}
      </Button>
    );
  });
};

const FilterFields = ({ serverOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="250px">
      <Grid item xs={6}>
        <SelectInput field="server_id" label="Server" size="small" margin="none" helperText={false}
          options={serverOptions}
        />
      </Grid>
    </Grid>
  );
};

