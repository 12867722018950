import React from 'react';
import TextInput from './fields/TextInput';
import SubmitButton from './fields/SubmitButton';
import { Divider } from '@mui/material';
import SelectInput from './fields/SelectInput';
import DateTimeInput from './fields/DateTimeInput';
import { useFormState } from 'informed';

export default function TransactionForm({ loading, paymentProviderOptions, dealerPaymentGroups }) {
  const formState = useFormState();

  return (
    <>
      <TextInput size="small" field="server.code" label="Server Code" disabled />
      <SelectInput
        size="small"
        field="type"
        label="Type"
        options={[
          {
            label: "Withdraw",
            value: "withdraw"
          },
          {
            label: "Deposit",
            value: "deposit"
          },
        ]}
      />
      <SelectInput
        size="small"
        margin="none"
        field='dealer_payment.provider'
        label='Dealer Payment Provider'
        options={paymentProviderOptions}
      />
      {formState.values?.dealer_payment?.provider && (
        <>
          <SelectInput
            size="small"
            margin="none"
            field='dealer_payment_id'
            label='Dealer Payment'
            options={dealerPaymentGroups[formState.values.dealer_payment.provider].map((payment) => (
              {
                label: `${payment.account_name} | ${payment.account_id}`,
                value: payment.id
              }
            ))}
          />
        </>
      )}

      <TextInput size="small" field="game_id_ref" label="Game ID" />
      <TextInput size="small" field="amount" label="Amount" />
      <TextInput size="small" field="bonus" label="Bonus" />
      <TextInput size="small" margin="none" field='note' label="Note" />
      <TextInput size="small" field="transaction_id_ref" label="Transaction ID" />
      <DateTimeInput size="small" field="transferred_at" label="Transferred At" />
      <Divider />
      <SubmitButton loading={loading}>Submit</SubmitButton>
    </>
  );
}
