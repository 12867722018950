import { Button, Grid } from '@mui/material';
import { fetchServers } from 'apiRequest/server';
import { fetchStaffs } from 'apiRequest/staff';
import SelectInput from 'components/forms/fields/SelectInput';
import ISTable from 'components/lSTable';
import TableButtonsContainer from 'components/TableButtonsContainer';
import useToken from 'hooks/useToken';
import PrivateLayout from 'layouts/private';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import dateTime from 'utils/datetime';


export default function StaffList() {
  const { token } = useToken();
  const [params, setParams] = useState({
    partials: ['server'],
    sort_by: {
      "key": "id",
      "order": "desc"
    }
  });

  const { isLoading, isError, data: staff, error } = useQuery(
    ["staff-fetchStaffs", params],
    () => {
      return fetchStaffs({ token, params });
    });

  const { data: servers } = useQuery("server-fetchServers", () => {
    return fetchServers({ token });
  });

  const serverOptions = servers?.data.data.map((server) => {
    return {
      value: server.id,
      label: server.code
    };
  });

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const handleSearchSubmit = (search) => {
    setParams({
      ...params,
      filters: { ...params.filters, search }
    });
  };

  const hanldeFilterSubmit = (data) => {
    setParams({
      ...params,
      filters: { ...params.filters, ...data }
    });
  };

  const hanldeSortClick = (data) => {
    setParams({ ...params, sort_by: data });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'server.name',
      label: 'Server',
      selector: row => row.server?.code,
    },
    {
      key: 'name',
      label: 'Name',
      selector: row => row.name,
    },
    {
      key: 'username',
      label: 'Username',
      selector: row => row.username,
    },
    {
      key: 'created_at',
      label: 'Date',
      selector: row => dateTime.format(row.created_at),
    },
    {
      key: 'actions',
      label: 'Actions',
      selector: row => <Button component={RouterLink} to={`/staff/${row.id}/update`}>Edit</Button>
    },
  ], []);

  return (
    <PrivateLayout loading={isLoading} isError={isError} error={error}>

      <TableButtonsContainer>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/staff/create`}
        >
          Add New Staff
        </Button>
      </TableButtonsContainer>

      <ISTable
        title="Staff"
        loading={isLoading}
        columns={columns}
        data={staff?.data.data}
        pagination
        count={staff?.data.total}
        page={staff?.data.current_page}
        rowsPerPage={staff?.data.per_page}
        onPageChange={handlePageChange}
        search
        searchFields={['name', 'username']}
        searchSubmit={handleSearchSubmit}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFieldsProps={{ serverOptions }}
        filterFields={['server_id']}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}

const FilterFields = ({ serverOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="250px">
      <Grid item xs={6}>
        <SelectInput field="server_id" label="Server" size="small" margin="none" helperText={false}
          options={serverOptions}
        />
      </Grid>
    </Grid>
  );
};
