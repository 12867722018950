import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";

export default function SubmitButton({ children, loading, ...rest }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 2
      }}
    >
      <LoadingButton
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        {...rest}
      >
        Submit
      </LoadingButton>
    </Box>
  );
}
