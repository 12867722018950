import fetch from "utils/fetch";

export const fetchDealerPaymentsAll = ({ params, ...rest }) => {
  return fetch({
    url: `/dealer-payments/all`,
    method: "GET",
    params,
    ...rest
  });
};

